import { Component } from 'vue-property-decorator'
import { fixDate } from '@/utils/general'
import { LeadView } from '@/components/forms/view/LeadView'

@Component
export class SaleOrderView extends LeadView {
  saleStatus = {
    pending: null,
    active: null,
    toConfirm: null,
    toUpdate: null,
    toContract: null,
    legalReview: null,
    approved: null,
  }

  documentStatus = {
    pending: null,
    legalReview: null,
    legalized: null,
    active: null,
    toRegister: null,
  }

  paymentInfo = {
    process: null,
    parameters: null,
  }

  documentFilesParameters = []
  filesProperties = {
    payment: {},
    evaluation: {},
    warranty: {},
    extraWarranty: {},
    tag: {},
    cav: {},
    penalties: {},
    legalReport: {},
    license: {},
    contract: {
      name: null,
    },
    buyerCard: {},
  }

  filesProcess = {
    payment: null,
    document: null,
    saleOrder: null,
    saleProduct: null,
    evaluation: null,
  }

  async mounted () {
    await this.setProcessStatus()
    const evaluationProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'evaluation' } },
    })

    this.filesProcess.evaluation = evaluationProcess[0].id

    const paymentProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })
    this.filesProcess.payment = paymentProcess[0].id
    const documentProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'document' } },
    })
    this.filesProcess.document = documentProcess[0].id
    const saleProductProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_product' } },
    })
    this.filesProcess.saleProduct = saleProductProcess[0].id
    const saleOrderProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    this.filesProcess.saleOrder = saleOrderProcess[0].id
    const paymentFile = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    this.setFileProperties('payment', paymentFile[0])

    const saleProductFiles = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_product' } } }] },
    })

    this.setFileProperties('warranty', saleProductFiles.find(file => file.name === 'warranty'))
    this.setFileProperties('extraWarranty', saleProductFiles.find(file => file.name === 'extra_warranty'))
    this.setFileProperties('tag', saleProductFiles.find(file => file.name === 'tag'))

    const saleOrder = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }] },
    })

    this.documentFilesParameters = saleOrder

    this.setFileProperties('buyerCard', saleOrder.find(file => file.name === 'buyer_card'))
    this.setFileProperties('cav', saleOrder.find(file => file.name === 'cav'))
    this.setFileProperties('penalties', saleOrder.find(file => file.name === 'penalty_certificate'))
    this.setFileProperties('legalReport', saleOrder.find(file => file.name === 'legal_report'))

    await this.evaluationProperties()
  }

  async evaluationProperties () {
    const evaluationFile = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }] },
    })
    const approval = evaluationFile.find(file => file.name === 'approval_letter')

    this.setFileProperties('evaluation', approval)
  }

  async setProcessStatus () {
    this.saleStatus.pending = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'pending' } } }] },
    })

    this.saleStatus.active = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    this.saleStatus.toUpdate = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'to_update' } } }] },
    })

    this.saleStatus.legalReview = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'legal_review' } } }] },
    })

    this.saleStatus.toConfirm = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'to_confirm' } } }] },
    })

    this.saleStatus.toContract = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'to_contract' } } }] },
    })

    this.saleStatus.approved = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'approved' } } }] },
    })

    this.documentStatus.pending = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'pending_buyer' } } }] },
    })

    this.documentStatus.legalReview = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'legal_review' } } }] },
    })

    this.documentStatus.legalized = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'legalize' } } }] },
    })

    this.documentStatus.active = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'active' } } }] },
    })
    this.documentStatus.toRegister = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'to_register' } } }] },
    })
  }

  setFileProperties (prop, file) {
    if (file) {
      Object.assign(this.filesProperties[prop], {
        accept: file.fileType.mimes,
        multiple: file.multiple,
        fileTypeId: file.fileType.id,
        name: file.name,
        label: file.description,
        required: file.required,
        icon: file.fileType.icon,
      })
    }
  }

  async insertWarranty (viewData) {
    const {
      formData: {
        fileWarranty,
        fileExtraWarranty,
      },
    } = viewData
    const {
      filesProcess: {
        saleProduct,
      },
      filesProperties: {
        extraWarranty,
        warranty: warrantyProperties,
      },
      currency,
    } = this
    const { formData: { warranty, comment }, saleOrder, fields, warrantyAmount, products } = viewData
    const item = fields.warranties.items.find(item => item.id === warranty)

    const exist = products?.find(product => product.name === 'warranty')

    const warrantyInserted = await this.pushData({
      model: 'SaleProduct',
      fields: {
        id: exist?.id,
        name: 'warranty',
        id_company: item.variant.agreementProduct.agreement.person.id,
        id_offer: warranty,
        cost: warrantyAmount,
        coverage: item.coverage,
        id_cost_currency: currency.id,
        id_sale_order: saleOrder.id,
        comment,
      },
    })

    if (fileWarranty?.length) {
      await this.handleFileType(fileWarranty, { properties: warrantyProperties }, saleProduct, warrantyInserted.id)
    }
    if (fileExtraWarranty?.length) {
      await this.handleFileType(fileExtraWarranty, { properties: extraWarranty }, saleProduct, warrantyInserted.id)
    }
  }

  async insertInsurance (viewData) {
    const { formData: { insurance, broker, cost, deductible, isWarranty }, saleOrder, products } = viewData
    if (!isWarranty) return
    const exist = products?.find(product => product.name === 'insurance')
    await this.pushData({
      model: 'SaleProduct',
      fields: {
        id: exist?.id,
        name: 'insurance',
        id_company: insurance.id,
        id_broker: broker.id,
        id_deductible_type: deductible,
        cost: Number(cost),
        id_sale_order: saleOrder.id,
      },
    })
  }

  async insertTag (viewData) {
    const {
      formData: { fileTag, isTag },
      saleOrder,
      filesProcess: { saleProduct },
      filesProperties: { tag },
      products,
    } = viewData
    if (!isTag) return
    const exist = products?.find(product => product.name === 'tag')
    const record = await this.pushData({
      model: 'SaleProduct',
      fields: {
        id: exist?.id,
        name: 'tag',
        id_sale_order: saleOrder.id,
      },
    })

    if (fileTag?.length) {
      await this.handleFileType(fileTag, { properties: tag }, saleProduct, record.id)
    }
  }

  async handleSale (viewData) {
    await this.insertWarranty(viewData)
    await this.insertInsurance(viewData)
    await this.insertTag(viewData)
  }

  async handleIntervener (viewData, document) {
    const { formData, saleOrder } = viewData

    const idFieldRepresent = document[0].fields.find(field => field.name === 'purchase_representative').id

    const interveners = await this.fetchData({
      query: { name: 'find', model: 'Intervener' },
      filter: { _and: [{ id_document: { _eq: saleOrder.documentId } }] },
      force: true,
    })

    const persons = interveners.map(intervener => intervener.person.id)
    const personsToInsert = formData.signers?.filter(signer => !persons.includes(signer.id))

    if (personsToInsert?.length) {
      await Promise.all(personsToInsert?.map(async representative => {
        await this.pushData(({
          model: 'Intervener',
          fields: {
            id_document: saleOrder.documentId,
            id_person: representative?.id,
            uid: representative.uid,
            business_name: representative.fullName,
            id_field: idFieldRepresent,
          },
        }))
      }))
    }

    const idBuyer = document[0].fields.find(field => field.name === 'buyer').id

    const acquirer = formData.acquirer?.filter(acq => !persons.includes(acq.id))

    if (acquirer?.length) {
      await Promise.all(acquirer?.map(async acq => {
        await this.pushData(({
          model: 'Intervener',
          fields: {
            id_document: saleOrder.documentId,
            id_person: acq?.id,
            uid: acq.uid,
            business_name: acq.fullName,
            id_field: idBuyer,
          },
        }))
      }))
    }
  }

  async handleFiles (viewData) {
    const {
      formData: {
        fileCav,
        filePenalties,
        contract,
        linkContract,
        fileLegalReport,
        linkLegalReport,
        fileLicense,
      },
      saleOrder,
    } = viewData
    const {
      filesProcess: {
        saleOrder: saleOrderProcess,
      },
      filesProperties: { cav, penalties, contract: contractProperties, buyerCard, legalReport: legalReportProperties },
    } = this

    if (fileCav?.length) {
      const file = await this.handleFileType(fileCav, { properties: cav }, saleOrderProcess, saleOrder.id)
      await this.handleFileProcessing(file, saleOrder.deal.id)
    }

    if (filePenalties?.length) {
      const file = await this.handleFileType(filePenalties, { properties: penalties }, saleOrderProcess, saleOrder.id)
      await this.handleFileProcessing(file, saleOrder.deal.id)
    }

    if (contract?.length) {
      await this.handleFileType(contract, { properties: contractProperties }, saleOrderProcess, saleOrder.id)
      const fileId = this.isArrayFiles(contract) ? contract[0]?.id : contract?.[0]?.file?.id
      if (linkContract?.length) {
        const fields = { id: fileId, source_link: linkContract }
        await this.pushData({ model: 'File', fields })
      }
    }

    if (fileLicense?.length) {
      await this.handleFileType(fileLicense, { properties: buyerCard }, saleOrderProcess, saleOrder.id)
    }

    if (fileLegalReport?.length) {
      await this.handleFileType(fileLegalReport, { properties: legalReportProperties }, saleOrderProcess, saleOrder.id)
      const fileId = this.isArrayFiles(fileLegalReport) ? fileLegalReport[0]?.id : fileLegalReport?.[0]?.file?.id
      const fields = { id: fileId, source_link: linkLegalReport }
      await this.pushData({ model: 'File', fields })
    }
  }

  async handleSaleOrderUpdating (viewData) {
    const { saleOrder, formData: { documentType, fileCav }, totalPercentageValidate, formData } = viewData

    await this.updateStock(viewData)
    await this.handleFiles(viewData)
    await this.handleSale(viewData)
    const paymentsToUpdate = formData.order.some(o => o.status.isToUpdating || o.status.isPending)

    if (totalPercentageValidate === '100.00' && saleOrder?.document?.id && fileCav?.length) {
      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: saleOrder.id,
          id_process_status: this.saleStatus.legalReview[0].id,
          id_document: saleOrder.documentId,
        },
      })

      await this.pushData({
        model: 'Document',
        fields: {
          id: saleOrder.documentId,
          id_process_status: this.documentStatus.legalReview?.[0]?.id,
          id_support_document: documentType.id,
        },
      })
    } else if (totalPercentageValidate === '100.00') {
      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: saleOrder.id,
          id_process_status: this.saleStatus.toConfirm[0].id,
        },
      })
    } else if (paymentsToUpdate) {
      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: saleOrder.id,
          id_process_status: this.saleStatus.active[0].id,
        },
      })
    }
  }

  async handleSaleOrder (viewData) {
    const {
      saleOrder,
      saleOrderToContract,
      formData: { documentType },
      totalPercentageValidate,
      stock,
    } = viewData

    await this.handleSale(viewData)

    if (!saleOrderToContract) {
      await this.createPayment(viewData)

      if (totalPercentageValidate !== '100.00') {
        await this.pushData({
          model: 'SaleOrder',
          fields: {
            id: saleOrder.id,
            id_process_status: this.saleStatus.active[0].id,
          },
        })
      } else {
        await this.pushData({
          model: 'SaleOrder',
          fields: {
            id: saleOrder.id,
            id_process_status: this.saleStatus.toConfirm[0].id,
          },
        })
      }
    } else {
      const document = await this.fetchData({
        query: { name: 'find', model: 'DocumentType' },
        filter: { _and: [{ name: { _eq: 'sale_purchase_contract' } }] },
      })

      await this.handleIntervener(viewData, document)

      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: saleOrder.id,
          id_process_status: this.saleStatus.legalReview?.[0]?.id,
          id_document: saleOrder.documentId,
        },
      })

      await this.pushData({
        model: 'Document',
        fields: {
          id: saleOrder.documentId,
          id_process_status: this.documentStatus.legalReview?.[0]?.id,
          id_support_document: documentType.id,
        },
      })
      await this.handleFiles(viewData)
    }

    await this.updateStock(viewData)
    await this.publishStock(stock.id)
  }

  async createPayment (viewData) {
    const { formData, filesProperties, filesProcess, saleOrder } = viewData

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ default: { _eq: true } }, { process: { table_name: { _eq: 'payment' } } }] },
    })

    const processValue = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    await Promise.all(formData.order.filter(o => !o.id).map(async item => {
      const fields = {
        id_payment_type: item.type.id,
        id_process: processValue[0].id,
        id_process_record: saleOrder.id,
        id_process_status: status[0].id,
        amount: item.amount,
        reference: item.reference,
        date: fixDate(item.date),
        id_payer: item.payer.id,
        id_issuing_bank: item?.issuingBank?.id,
        comment: item.comment,
        id_deal: saleOrder.deal.id,
        expiration: fixDate(item.expirationDate),
        payment_count: item.quotas,
        id_financial_account: item.account?.id,
      }
      if (!fields?.reference) {
        delete fields.reference
      }
      if (!fields?.id_issuing_bank) {
        delete fields.id_issuing_bank
      }
      if (!fields?.expiration) {
        delete fields.expiration
      }
      if (!fields?.payment_count) {
        delete fields.payment_count
      }

      const pay = await this.pushData({ model: 'Payment', fields })
      if (item.paymentBackup.length) {
        await this.handleFileType(item.paymentBackup, { properties: filesProperties.payment }, filesProcess.payment, pay.id)
      }
    }))
  }

  async updateStock (viewData) {
    const { saleOrder: { deal: { stock: { id } }, reserve } } = viewData
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ status: { name: { _eq: 'selling' } } }, { process: { table_name: { _eq: 'stock' } } }] },
    })

    const fields = {
      id,
      id_process_status: status[0].id,
    }

    await this.pushData({ model: 'Stock', fields })

    const closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    const filterSuccess = {
      _and: [
        { type: { name: { _eq: 'successful' } } },
        { status: { process: { table_name: { _eq: 'reserve' } } } },
      ],
    }

    const closing = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: filterSuccess,
    })

    if (reserve?.id && !reserve?.closingReason) {
      const reserveFields = {
        id: reserve.id,
        id_process_status: closed[0]?.id,
        id_closing_reason: closing[0]?.id,
      }

      await this.pushData({ model: 'Reserve', fields: reserveFields })
    }
  }

  async getPenalties (idFileProcess) {
    if (!idFileProcess) return
    const processTrafficTickets = await this.fetchData({
      query: { name: 'find', model: 'ProcessTrafficTicket' },
      filter: { id_file_process: { _eq: idFileProcess } },
      force: true,
    })

    return processTrafficTickets || []
  }
}
